import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Word } from "../models/word";
import latest from "../assets/latest.json";

const latestWords = latest as Word[];

type State = {
  days: number[];

  lapsed: Word[];
  future: Word[];

  shouldScramble: boolean;
  shouldRepeat: boolean;
};

type Actions = {
  setDays: (days: number[]) => void;
  setShouldScramble: (shouldScramble: boolean) => void;
  setShouldRepeat: (shouldRepeat: boolean) => void;

  moveNext: () => void;
  movePrevious: () => void;
  restart: () => void;
  refresh: () => void;
};

const sortWords = (words: Word[]) => {
  const sortedWords = [];

  for (const word of latestWords) {
    for (const w of words) {
      if (w.blanked_sentence === word.blanked_sentence) {
        sortedWords.push(word);
        break;
      }
    }
  }

  return sortedWords;
};

export const useWords = create<State & Actions>()(
  persist(
    (set) => ({
      days: [],

      lapsed: [],
      future: [],

      shouldScramble: false,
      shouldRepeat: false,

      setDays: (days) => {
        set((state) => {
          const previousDays = state.days;

          const newDays = days.filter((day) => !previousDays.includes(day));
          const removedDays = previousDays.filter((day) => !days.includes(day));

          const newWords = latestWords.filter((word) =>
            newDays.includes(word.day)
          );
          const newLapsed = state.lapsed.filter(
            (word) => !removedDays.includes(word.day)
          );
          let newFuture = state.future
            .filter((word) => !removedDays.includes(word.day))
            .concat(newWords);

          if (state.shouldScramble) {
            newFuture.sort(() => Math.random() - 0.5);
          } else {
            newFuture = sortWords(newFuture);
          }

          return {
            days,
            lapsed: newLapsed,
            future: newFuture,
          };
        });
      },
      setShouldScramble: (shouldScramble) => {
        set((state) => {
          let future = [...state.future];
          if (shouldScramble) {
            future.sort(() => Math.random() - 0.5);
          } else {
            future = sortWords(future);
          }
          return { future, shouldScramble };
        });
      },
      setShouldRepeat: (shouldRepeat) => set({ shouldRepeat }),
      moveNext: () => {
        set((state) => {
          if (state.future.length <= 1) {
            if (state.shouldRepeat) {
              let newFuture = [...state.lapsed, ...state.future];

              if (state.shouldScramble) {
                newFuture.sort(() => Math.random() - 0.5);
              } else {
                newFuture = sortWords(newFuture);
              }

              return {
                future: newFuture,
                lapsed: [],
              };
            } else if (state.lapsed.length === 0) {
              return state;
            }
          }
          const [current, ...future] = state.future;
          return { future, lapsed: [current, ...state.lapsed] };
        });
      },
      movePrevious: () => {
        set((state) => {
          if (state.lapsed.length === 0) {
            return state;
          }
          const [current, ...lapsed] = state.lapsed;
          return { lapsed, future: [current, ...state.future] };
        });
      },
      restart: () => {
        set((state) => {
          let newFuture = [...state.lapsed, ...state.future];

          if (state.shouldScramble) {
            newFuture.sort(() => Math.random() - 0.5);
          } else {
            newFuture = sortWords(newFuture);
          }

          return {
            future: newFuture,
            lapsed: [],
          };
        });
      },
      refresh: () => {
        set((state) => {
          const newFuture = [];
          const newLapsed = [];

          for (const word of state.future) {
            let found = false;
            for (const w of latestWords) {
              if (w.blanked_sentence === word.blanked_sentence) {
                newFuture.push(w);
                found = true;
                break;
              }
            }
            if (!found) {
              console.warn(
                "[refresh] Non compatible word found in future, Resetting the app"
              );
              return {
                days: [],

                lapsed: [],
                future: [],

                shouldScramble: false,
                shouldRepeat: false,
              };
            }
          }
          for (const word of state.lapsed) {
            let found = false;
            for (const w of latestWords) {
              if (w.blanked_sentence === word.blanked_sentence) {
                newLapsed.push(w);
                found = true;
                break;
              }
            }
            if (!found) {
              console.warn(
                "[refresh] Non compatible word found in lapsed, Resetting the app"
              );
              return {
                days: [],

                lapsed: [],
                future: [],

                shouldScramble: false,
                shouldRepeat: false,
              };
            }
          }

          console.log("[refresh] Successfully refreshed the words!");

          return {
            future: newFuture,
            lapsed: newLapsed,
          };
        });
      },
    }),
    { name: "words-storage", storage: createJSONStorage(() => localStorage) }
  )
);
