import React from "react";
import ReactDOM from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import App from "./App.tsx";
import "./index.css";
import { usePreferences } from "./stores/preferences.ts";
import * as Sentry from "@sentry/react";
import { ampli } from "./ampli";

Sentry.init({
  dsn: "https://e0eaa4ee0dbc1cf205df1565c78e5fb0@o4504177806540800.ingest.sentry.io/4506227007160320",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ampli.load({
  environment: "ujhs",
});

window.addEventListener("beforeunload", () => {
  ampli.flush();
});

export function UIProvider() {
  const theme = usePreferences((state) => state.theme);

  return (
    <NextUIProvider>
      <main className={`${theme} text-foreground bg-background`}>
        <App />
      </main>
    </NextUIProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <UIProvider />
  </React.StrictMode>
);
