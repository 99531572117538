import styled from "styled-components";
import { useWords } from "./stores/words";
import { usePreferences } from "./stores/preferences";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@nextui-org/react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { ampli } from "./ampli";
import axios from "axios";

export default function Completion() {
  const currentWord = useWords((state) => state.future[0]);
  const moveNext = useWords((state) => state.moveNext);
  const movePrevious = useWords((state) => state.movePrevious);
  const restart = useWords((state) => state.restart);
  const { lapsed, future } = useWords((state) => {
    return { lapsed: state.lapsed, future: state.future };
  });
  const showFirstLetter = usePreferences((state) => state.showFirstLetter);
  const adjustInputLength = usePreferences((state) => state.adjustInputLength);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reporting, setReporting] = useState(false);

  const [verifiedList, setVerifiedList] = useState(new Set<number>());
  const [writings, setWritings] = useState<string[]>([]);

  const { width, height } = useWindowSize();

  const sliced = currentWord?.blanked_sentence?.split("_____");
  const answers = currentWord?.answers;

  useEffect(() => {
    setVerifiedList(new Set<number>());
    if (currentWord === undefined) setWritings([]);
    else setWritings(new Array(currentWord.answers.length).fill(""));
  }, [currentWord]);

  return (
    <CompletionContainer>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => {
            let filledIterator = 0;
            const filled = currentWord.blanked_sentence?.replace(
              /_____/g,
              () => {
                if (writings[filledIterator] !== "") {
                  return writings[filledIterator++];
                } else {
                  return "";
                }
              }
            );

            return (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  오류 신고
                </ModalHeader>
                <ModalBody>
                  <p>답에 오류가 있다면 올바른 답을 채워서 신고해주세요.</p>
                  <p>
                    Day {currentWord?.day} - {currentWord.word}
                  </p>
                  <p>{filled}</p>
                  <p>입력하신 문장이 책의 예문과 같나요?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="light"
                    onPress={onClose}
                    isDisabled={reporting}
                  >
                    수정하기
                  </Button>
                  <Button
                    color="primary"
                    isLoading={reporting}
                    onPress={() => {
                      setReporting(true);
                      axios
                        .post("https://vocab-backend.neulus.com/proposal", {
                          blanked: currentWord.blanked_sentence,
                          answers: writings,
                        })
                        .then(() => {
                          setReporting(false);
                          moveNext();
                          toast.success("신고가 완료되었습니다! 감사합니다.");
                          onClose();
                        })
                        .catch(() => {
                          setReporting(false);
                          toast.error(
                            "신고 중 오류가 발생했습니다. 다시 시도해주세요."
                          );
                        });
                    }}
                  >
                    맞음
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </ModalContent>
      </Modal>
      <AnimatePresence>
        {currentWord == undefined ? (
          lapsed.length !== 0 ? (
            <>
              <Confetti width={width} height={height} numberOfPieces={100} />
              <CompletedMessage>학습이 끝났습니다!</CompletedMessage>
              <Button
                onClick={() => {
                  restart();
                }}
              >
                재시작
              </Button>
            </>
          ) : (
            <h1>설정에서 학습할 범위을 선택해주세요.</h1>
          )
        ) : (
          <Container>
            <Word>
              {sliced?.map((slice, index) => {
                if (answers[index] !== undefined) {
                  writings[index] = writings[index] || "";
                }

                return (
                  <p key={index} style={{ display: "inline" }}>
                    {slice}
                    {answers[index] == undefined ? null : (
                      <Input
                        type="text"
                        width={
                          adjustInputLength
                            ? answers[index].length * 16 + 10 + "px"
                            : "200px"
                        }
                        value={writings[index]}
                        placeholder={showFirstLetter ? answers[index][0] : ""}
                        autoFocus
                        onChange={(event) => {
                          const newWritings = [...writings];
                          newWritings[index] = event.target.value;
                          setWritings(newWritings);

                          if (
                            event.target.value.trim().toLowerCase() ===
                            answers[index].trim().toLowerCase()
                          ) {
                            setVerifiedList(new Set([...verifiedList, index]));
                          } else {
                            const newSet = new Set([...verifiedList]);
                            newSet.delete(index);
                            setVerifiedList(newSet);
                          }
                        }}
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                        onKeyUp={(event) => {
                          event.preventDefault();
                        }}
                        onKeyDown={(event) => {
                          if (event.key !== "Enter") return;

                          event.preventDefault();
                          if (verifiedList.size === answers.length) {
                            toast.success("정답!");
                            ampli.answeredCorrectly({
                              day: currentWord.day.toString(),
                              blanked: currentWord.blanked_sentence,
                              filled: currentWord.sentence,
                            });
                            moveNext();
                          } else {
                            const checkSomethingIsWritten = writings.every(
                              (e) => e !== ""
                            );

                            if (
                              checkSomethingIsWritten &&
                              writings.length === answers.length
                            ) {
                              let filledIterator = 0;
                              ampli.answeredWrongly({
                                day: currentWord.day.toString(),
                                blanked: currentWord.blanked_sentence,
                                filled: currentWord.blanked_sentence.replace(
                                  /_____/g,
                                  () => {
                                    if (writings[filledIterator] !== "") {
                                      return writings[filledIterator++];
                                    } else {
                                      return "_____";
                                    }
                                  }
                                ),
                              });

                              toast.error(
                                "정답이 아닙니다. 다시 시도해주세요."
                              );
                            } else {
                              toast.error("빈칸을 채워주세요.");
                            }
                          }
                        }}
                      />
                    )}
                  </p>
                );
              })}
            </Word>
            <Word>{currentWord.translation}</Word>
          </Container>
        )}
      </AnimatePresence>
      {future.length !== 0 ? (
        <>
          <RowContainer>
            {lapsed.length !== 0 ? (
              <Button
                style={{
                  marginRight: "2.5px",
                }}
                variant="ghost"
                onClick={() => {
                  movePrevious();
                }}
              >
                뒤로가기
              </Button>
            ) : null}
            <Button
              style={{
                marginLeft: "2.5px",
                marginRight: "2.5px",
              }}
              color="primary"
              onClick={() => {
                if (verifiedList.size === answers.length) {
                  toast.success("정답!");
                  ampli.answeredCorrectly({
                    day: currentWord.day.toString(),
                    blanked: currentWord.blanked_sentence,
                    filled: currentWord.sentence,
                  });
                  moveNext();
                } else {
                  const checkSomethingIsWritten = writings.every(
                    (e) => e !== ""
                  );

                  if (
                    checkSomethingIsWritten &&
                    writings.length === answers.length
                  ) {
                    let filledIterator = 0;
                    ampli.answeredWrongly({
                      day: currentWord.day.toString(),
                      blanked: currentWord.blanked_sentence,
                      filled: currentWord.blanked_sentence.replace(
                        /_____/g,
                        () => {
                          if (writings[filledIterator] !== "") {
                            return writings[filledIterator++];
                          } else {
                            return "_____";
                          }
                        }
                      ),
                    });
                    toast.error("정답이 아닙니다. 다시 시도해주세요.");
                  } else {
                    toast.error("빈칸을 채워주세요.");
                  }
                }
              }}
            >
              다음
            </Button>
          </RowContainer>
          <RowContainer
            style={{
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                marginRight: "2.5px",
              }}
              variant="ghost"
              onClick={() => {
                moveNext();
              }}
              size="sm"
            >
              스킵
            </Button>
            <Popover>
              <PopoverTrigger>
                <Button
                  style={{
                    marginLeft: "2.5px",
                    marginRight: "2.5px",
                  }}
                  variant="ghost"
                  size="sm"
                >
                  정답
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="px-1 py-2">
                  <div className="text-small font-bold">정답</div>
                  <div className="text-tiny">{currentWord.sentence}</div>
                </div>
              </PopoverContent>
            </Popover>
            <Popover>
              <PopoverTrigger>
                <Button
                  style={{
                    marginLeft: "2.5px",
                    marginRight: "2.5px",
                  }}
                  variant="ghost"
                  size="sm"
                >
                  단어
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="px-1 py-2">
                  <div className="text-small font-bold">단어</div>
                  <div className="text-tiny">{currentWord.word}</div>
                </div>
              </PopoverContent>
            </Popover>
            <Button
              style={{
                marginLeft: "2.5px",
              }}
              variant="light"
              onClick={() => {
                onOpen();
              }}
              size="sm"
              isDisabled={currentWord && currentWord.verified === true}
            >
              {currentWord && currentWord.verified === true
                ? "검증됨"
                : "오류 신고"}
            </Button>
          </RowContainer>
        </>
      ) : null}
    </CompletionContainer>
  );
}

const CompletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 24px;

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`;

const Word = styled.h1`
  text-align: center;
  word-break: break-word;
`;

const CompletedMessage = styled.h1`
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 15px;
`;

const RowContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

const Input = styled.input<{ width: string }>`
  width: ${(props) => props.width};
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px 10px;

  &:focus {
    outline: none;
  }
`;
