import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type State = {
  theme: "light" | "dark";
  showFirstLetter: boolean;
  adjustInputLength: boolean;
};

type Actions = {
  setTheme: (theme: "light" | "dark") => void;
  setShowFirstLetter: (showFirstLetter: boolean) => void;
  setAdjustInputLength: (adjustInputLength: boolean) => void;
};

export const usePreferences = create<State & Actions>()(
  persist(
    (set) => ({
      theme: "dark",
      showFirstLetter: true,
      adjustInputLength: true,
      setTheme: (theme) => set({ theme }),
      setShowFirstLetter: (showFirstLetter) => set({ showFirstLetter }),
      setAdjustInputLength: (adjustInputLength) => set({ adjustInputLength }),
    }),
    {
      name: "preferences-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
