import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Switch,
  Select,
  SelectItem,
  Link,
} from "@nextui-org/react";
import { useWords } from "./stores/words";
import { usePreferences } from "./stores/preferences";
import {
  IoSettingsOutline,
  IoMoonOutline,
  IoSunnyOutline,
} from "react-icons/io5";
import { ampli } from "./ampli";

export default function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showFirstLetter = usePreferences((state) => state.showFirstLetter);
  const setShowFirstLetter = usePreferences(
    (state) => state.setShowFirstLetter
  );
  const theme = usePreferences((state) => state.theme);
  const setTheme = usePreferences((state) => state.setTheme);

  const days = useWords((state) => state.days);
  const shouldScramble = useWords((state) => state.shouldScramble);
  const shouldRepeat = useWords((state) => state.shouldRepeat);
  const adjustInputLength = usePreferences((state) => state.adjustInputLength);

  const setDays = useWords((state) => state.setDays);
  const setShouldScramble = useWords((state) => state.setShouldScramble);
  const setShouldRepeat = useWords((state) => state.setShouldRepeat);
  const setAdjustInputLength = usePreferences(
    (state) => state.setAdjustInputLength
  );

  const toggleAll = () => {
    if (days.length === testDays.length) {
      setDays([]);
    } else {
      setDays(testDays);
    }
  };

  const testDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  return (
    <>
      <div className="flex flex-wrap gap-3 fixed bottom-10 right-10">
        <Button
          variant="shadow"
          color="primary"
          isIconOnly
          size="lg"
          onPress={() => {
            ampli.openSettings();
            onOpen();
          }}
        >
          <IoSettingsOutline />
        </Button>
      </div>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">설정</ModalHeader>
              <ModalBody>
                <Switch
                  isSelected={showFirstLetter}
                  onValueChange={setShowFirstLetter}
                  size="md"
                >
                  첫 번째 알파벳 표시
                </Switch>
                <Switch
                  isSelected={shouldScramble}
                  onValueChange={setShouldScramble}
                  size="md"
                >
                  순서 랜덤화
                </Switch>
                <Switch
                  isSelected={shouldRepeat}
                  onValueChange={setShouldRepeat}
                  size="md"
                >
                  무한 반복
                </Switch>
                <Switch
                  size="md"
                  color="primary"
                  isSelected={adjustInputLength}
                  onValueChange={setAdjustInputLength}
                >
                  글자 수에 맞춰 입력란 크기 조절
                </Switch>
                <Switch
                  size="md"
                  color="primary"
                  isSelected={theme === "light" ? true : false}
                  onValueChange={(isSelected) => {
                    setTheme(isSelected ? "light" : "dark");
                  }}
                  startContent={<IoSunnyOutline />}
                  endContent={<IoMoonOutline />}
                >
                  테마
                </Switch>
                <Button
                  size="sm"
                  className="w-fit"
                  color="primary"
                  onPress={toggleAll}
                >
                  전체 선택
                </Button>
                <Select
                  label="학습 범위"
                  placeholder="DAY 선택"
                  size="md"
                  selectionMode="multiple"
                  selectedKeys={new Set(days.map((e) => e.toString()))}
                  onSelectionChange={(e) => {
                    setDays(Array.from(e).map((e) => parseInt(e.toString())));
                    return undefined;
                  }}
                >
                  {testDays.map((e) => (
                    <SelectItem key={e.toString()} value={e.toString()}>
                      {"Day " + e}
                    </SelectItem>
                  ))}
                </Select>

                <h2>
                  Project by 2학년 12반{" "}
                  <Link
                    href="https://www.instagram.com/dudwn_dbs/"
                    underline="always"
                  >
                    윤영주
                  </Link>
                  ,{" "}
                  <Link
                    href="https://www.instagram.com/_minseoklee/"
                    underline="always"
                  >
                    이민석
                  </Link>
                  ,{" "}
                  <Link
                    href="https://www.instagram.com/gimjisu4136/"
                    underline="always"
                  >
                    김지수
                  </Link>{" "}
                </h2>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onPress={onClose}>
                  완료
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
