import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
  useDisclosure,
} from "@nextui-org/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoStatsChart } from "react-icons/io5";
import { ampli } from "./ampli";
import latest from "./assets/latest.json";
import { Word } from "./models/word";

const latestWords = latest as Word[];

export default function Statics() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState<
    {
      day: number;
      word: string;
      count: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isOpen) return;

    // 귀찮다
    setLoading(true);
    axios
      .get(
        `https://vocab-backend.neulus.com/worst?device=${ampli.client.getDeviceId()}`
      )
      .then((res) => {
        setLoading(false);
        if (!res.data) return;
        const stuff = res.data.map(
          (item: { blanked: string; count: number }) => {
            const data = latestWords.find(
              (word) => word.blanked_sentence === item.blanked
            );
            if (!data) return null;
            return {
              day: data.day,
              word: data.word,
              count: item.count,
            };
          }
        );

        setData(
          stuff.filter(
            (
              item: null | {
                day: number;
                word: string;
                count: number;
              }
            ) => item !== null
          )
        );
      });
  }, [isOpen]);

  return (
    <>
      <div
        className="flex flex-wrap gap-3 fixed bottom-10 right-20"
        style={{
          marginRight: "0.9rem",
        }}
      >
        <Button
          variant="shadow"
          color="primary"
          isIconOnly
          size="lg"
          onPress={() => {
            onOpen();
          }}
        >
          <IoStatsChart />
        </Button>
      </div>

      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => {
            return (
              <>
                <ModalHeader className="flex flex-col gap-1">통계</ModalHeader>
                <ModalBody
                  style={{
                    maxHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  <Table aria-label="틀린 횟수">
                    <TableHeader
                      columns={[
                        {
                          key: "day",
                          label: "Day",
                        },
                        {
                          key: "word",
                          label: "단어",
                        },
                        {
                          key: "count",
                          label: "틀린 횟수",
                        },
                      ]}
                    >
                      {(column) => (
                        <TableColumn key={column.key}>
                          {column.label}
                        </TableColumn>
                      )}
                    </TableHeader>
                    <TableBody
                      items={data}
                      loadingContent={<Spinner />}
                      loadingState={loading ? "loading" : "idle"}
                    >
                      {(item) => (
                        <TableRow key={item.word + item.day}>
                          {(columnKey) => (
                            <TableCell>
                              {getKeyValue(item, columnKey)}
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onPress={onClose}>
                    완료
                  </Button>
                </ModalFooter>
              </>
            );
          }}
        </ModalContent>
      </Modal>
    </>
  );
}
