import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  useDisclosure,
} from "@nextui-org/react";
import { useWords } from "./stores/words";
import styled from "styled-components";
import Completion from "./Completion";
import Settings from "./Settings";
import toast, { Toaster } from "react-hot-toast";
import config from "./config";
import { useEffect } from "react";
import { ampli } from "./ampli";
import Statics from "./Statics";

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { lapsed, future } = useWords((state) => {
    return { lapsed: state.lapsed, future: state.future };
  });
  const currentWord = useWords((state) => state.future[0]);
  const refresh = useWords((state) => state.refresh);

  useEffect(() => {
    if (localStorage.getItem("first") === null) {
      onOpen();
      localStorage.setItem("first", "true");
    }
    refresh();

    const session = (Math.random() * 100000).toFixed(0).toString();

    fetch(
      `https://vocab-backend.neulus.com/version?device=${
        ampli.client.getDeviceId() ?? session
      }`
    )
      .then((res) => res.text())
      .catch(() => {})
      .then((version) => {
        if (version !== config.appVersion) {
          toast.loading("새로운 버전이 있습니다. 업데이트 중...");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch(() => {});

    setInterval(() => {
      fetch(
        `https://vocab-backend.neulus.com/version?device=${
          ampli.client.getDeviceId() ?? session
        }`
      )
        .then((res) => res.text())
        .catch(() => {})
        .then((version) => {
          if (version !== config.appVersion) {
            toast.loading("새로운 버전이 있습니다. 업데이트 중...");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch(() => {});
    }, 1000 * 60 * 2);
  }, []);

  return (
    <Container>
      {/* Announcement */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                공지사항
              </ModalHeader>
              <ModalBody>
                <p>
                  운정고등학교 단어 학습 사이트에 오신 것을 환영합니다! 언제
                  어디서나 편하게 학습을 할 수 있도록 제작하였으니 마음껏
                  이용하시기 바랍니다.
                </p>
                <p>
                  그러나 사이트에 예기치 못한 버그나 단어 누락 또는 오류 등이
                  발생할 수 있습니다. 그 부분에 대해서는 저희가 책임지지 않으니
                  너무 사이트에만 의존하지 않고 책을 꼼꼼이 확인하면서
                  이용하시는 것을 권장드립니다.
                </p>
                <p>
                  잘못된 문장 발견시 꼭 신고 부탁드리고 수행평가에서 좋은 결과
                  있기를 기원합니다!
                </p>
                <p
                  style={{
                    textAlign: "right",
                  }}
                >
                  2학년 12반{" "}
                  <Link
                    href="https://www.instagram.com/dudwn_dbs/"
                    underline="always"
                  >
                    윤영주
                  </Link>
                  ,{" "}
                  <Link
                    href="https://www.instagram.com/_minseoklee/"
                    underline="always"
                  >
                    이민석
                  </Link>
                  ,{" "}
                  <Link
                    href="https://www.instagram.com/gimjisu4136/"
                    underline="always"
                  >
                    김지수
                  </Link>{" "}
                  드림
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="success" onPress={onClose}>
                  이해했습니다
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ToastConntainer>
        <Toaster />
      </ToastConntainer>
      <Header>
        <h1
          style={{
            // marginBottom: "30px",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {currentWord?.day}일차
        </h1>
        <h1>{lapsed.length + "/" + (future.length + lapsed.length)}</h1>
        <Progress
          aria-label="Progress"
          size="sm"
          value={(lapsed.length / (future.length + lapsed.length)) * 100}
        />
      </Header>
      <Completion />
      <Statics />
      <Settings />
    </Container>
  );
}

const ToastConntainer = styled.div`
  font-size: 18px;
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem;
  padding-top: 0.5rem;
`;

export default App;
